import React, { useState } from 'react';
import api from './axiosConfig'; // Make sure this import is correct
import './adminstyles.css';

const PasswordRecovery = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/request_password_reset', { email });
            
            if (response.status === 200) {
                setMessage('Password reset email sent successfully');
                console.log('Password reset email sent');
            } else {
                setMessage('Unexpected error occurred');
                console.error('Unexpected response status:', response.status);
            }
        } catch (error) {
            setMessage('Error requesting password reset');
            console.error('Error requesting password reset:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Request Password Reset</h2>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
            />
            <button type="submit">Request Password Reset</button>
            {message && <div>{message}</div>}
        </form>
    );
};

export default PasswordRecovery;