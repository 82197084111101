import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import api from './axiosConfig';
import './adminstyles.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        business_name: '',
        business_address_line1: '',
        business_address_line2: '',
        business_address_line3: '',
        postcode: '',
        phone_number: '',
        vat_number: '',
        vat_rate: '',
        username: '',
        email: '',
        password: '',
        plan: 'monthly' // Default to monthly plan
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        try {
            const response = await api.post('/register', {
                ...formData,
                product_id: formData.plan === 'monthly' ? 'prod_QOJqIqcLBuKIbA' : 'prod_QOJpU2Tw66J1Dp'
            });

            if (response.status === 200 || response.status === 201) {
                // Redirect to Stripe Checkout
                const stripe = await stripePromise;
                const result = await stripe.redirectToCheckout({
                    sessionId: response.data.sessionId
                });

                if (result.error) {
                    setError(result.error.message);
                }
            } else {
                setError('Unexpected response from server');
            }
        } catch (err) {
            console.error('Registration error:', err);
            setError(err.response?.data?.error || 'An error occurred during registration');
        }
    };

    return (
        <div className="register-container">
            <h2>Register for Salon Management System</h2>
            <form onSubmit={handleSubmit} className="register-form">
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    required
                />
                <input
                    type="text"
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                    placeholder="Business Name"
                    required
                />
                <input
                    type="text"
                    name="business_address_line1"
                    value={formData.business_address_line1}
                    onChange={handleChange}
                    placeholder="Address Line 1"
                    required
                />
                <input
                    type="text"
                    name="business_address_line2"
                    value={formData.business_address_line2}
                    onChange={handleChange}
                    placeholder="Address Line 2"
                />
                <input
                    type="text"
                    name="business_address_line3"
                    value={formData.business_address_line3}
                    onChange={handleChange}
                    placeholder="Address Line 3"
                />
                <input
                    type="text"
                    name="postcode"
                    value={formData.postcode}
                    onChange={handleChange}
                    placeholder="Postcode"
                    required
                />
                <input
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    required
                />
                <input
                    type="text"
                    name="vat_number"
                    value={formData.vat_number}
                    onChange={handleChange}
                    placeholder="VAT Number"
                />
                <input
                    type="text"
                    name="vat_rate"
                    value={formData.vat_rate}
                    onChange={handleChange}
                    placeholder="VAT Rate"
                    required
                />
                <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    placeholder="Username"
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                />
                <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                    required
                />
                <select
                    name="plan"
                    value={formData.plan}
                    onChange={handleChange}
                    required
                >
                    <option value="monthly">Monthly Plan (£59.99/month)</option>
                    <option value="yearly">Yearly Plan (£500.00/year)</option>
                </select>
                <button type="submit">Register and Subscribe</button>
            </form>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default Register;