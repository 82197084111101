import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

console.log('React app initializing');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

console.log('React app rendered');

// Add a MutationObserver to detect any changes to the DOM
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'childList') {
      console.log('DOM changed:', mutation);
    }
  });
});

observer.observe(document.body, { childList: true, subtree: true });