import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const Absences = ({ employee }) => {
  const [absences, setAbsences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (employee && employee.id) {
      fetchAbsences(employee.id);
    }
  }, [employee]);

  const fetchAbsences = async (employeeId) => {
    try {
      setLoading(true);
      const response = await api.get(`/absences/employee/${employeeId}`);
      if (response.data && Array.isArray(response.data)) {
        setAbsences(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setError('Unexpected data format received');
      }
    } catch (err) {
      console.error('Error fetching absences:', err);
      setError('Failed to fetch absences: ' + (err.response?.data?.message || err.message));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Typography>Loading absences...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Days Absent</TableCell>
            <TableCell>Return to Work Date</TableCell>
            <TableCell>Fit Note</TableCell>
            <TableCell>Self Certification</TableCell>
            <TableCell>Interview Completed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {absences.map((absence) => (
            <TableRow key={absence.id}>
              <TableCell>{new Date(absence.date).toLocaleDateString()}</TableCell>
              <TableCell>{absence.reason}</TableCell>
              <TableCell>{absence.number_of_days_absent}</TableCell>
              <TableCell>{absence.return_to_work_date ? new Date(absence.return_to_work_date).toLocaleDateString() : 'N/A'}</TableCell>
              <TableCell>{absence.fit_note_received ? 'Yes' : 'No'}</TableCell>
              <TableCell>{absence.self_certification_form ? 'Yes' : 'No'}</TableCell>
              <TableCell>{absence.back_to_work_interview_completed ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Absences;