import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import api from './axiosConfig';
import './adminstyles.css';

const PasswordReset = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }
        try {
            const response = await api.post(`/reset_password/${token}`, { password });
            setMessage(response.data.message || 'Password reset successfully.');
        } catch (error) {
            console.error('Error resetting password:', error);
            setMessage('An error occurred while resetting the password.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Reset Password</h2>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter new password"
                required
            />
            <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
                required
            />
            <button type="submit">Reset Password</button>
            {message && <div>{message}</div>}
        </form>
    );
};

export default PasswordReset;