import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { 
  Box, 
  Paper, 
  Typography, 
  CircularProgress, 
  Alert,
  AlertTitle 
} from '@mui/material';
import DatePickerComponent from './DatePickerComponent';
import GaugeChart from 'react-gauge-chart';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAuth } from './AuthContext';
import dayjs from 'dayjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(value);
};

const formatValue = (title, value) => {
  // Format numbers as whole numbers for count-based metrics
  if (title === "Customer Count" || title === "Pending Requests" || title === "Reminders") {
    return Math.round(value).toString();
  }
  // Format as currency for financial metrics
  return formatCurrency(value);
};

const KPIBox = ({ title, value, growth }) => {
  return (
    <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="h5">
        {formatValue(title, value)}
      </Typography>
      {growth !== undefined && (
        <Typography 
          variant="body2" 
          color={growth >= 0 ? "success.main" : "error.main"}
        >
          {growth > 0 ? '+' : ''}{growth}%
        </Typography>
      )}
    </Paper>
  );
};

const ManagerDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    revenueData: [],
    kpiData: {
      totalExpenditure: { value: 0, growth: 0 },
      outputVat: { value: 0, growth: 0 },
      inputVat: { value: 0, growth: 0 },
      vatDifference: { value: 0, growth: 0 },
      customerCount: { value: 0, growth: 0 },
      averageTicket: { value: 0, growth: 0 },
      pendingRequests: { value: 0 },
      reminders: { value: 0 }
    }
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [datesSelected, setDatesSelected] = useState(false);

  const savedStartDate = localStorage.getItem('dashboardStartDate');
  const savedEndDate = localStorage.getItem('dashboardEndDate');

  const [startDate, setStartDate] = useState(
    savedStartDate ? dayjs(savedStartDate) : null
  );
  const [endDate, setEndDate] = useState(
    savedEndDate ? dayjs(savedEndDate) : null
  );

  const handleStartDateChange = (newDate) => {
    setStartDate(newDate);
    localStorage.setItem('dashboardStartDate', newDate ? newDate.format('YYYY-MM-DD') : '');
    setDatesSelected(true);
  };

  const handleEndDateChange = (newDate) => {
    setEndDate(newDate);
    localStorage.setItem('dashboardEndDate', newDate ? newDate.format('YYYY-MM-DD') : '');
    setDatesSelected(true);
  };

  const fetchDashboardData = async () => {
    if (!startDate || !endDate) return;

    try {
      setLoading(true);
      setError(null);

      console.log('Fetching data with dates:', {
        start: startDate.format('YYYY-MM-DD'),
        end: endDate.format('YYYY-MM-DD')
      });

      const [revenueRes, kpiRes] = await Promise.all([
        api.get('/dashboard/revenue_data', {
          params: {
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD')
          }
        }),
        api.get('/dashboard/kpi_data', {
          params: {
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD')
          }
        })
      ]);

      setDashboardData({
        revenueData: Array.isArray(revenueRes.data) ? revenueRes.data : [],
        kpiData: kpiRes.data || {
          totalExpenditure: { value: 0, growth: 0 },
          outputVat: { value: 0, growth: 0 },
          inputVat: { value: 0, growth: 0 },
          vatDifference: { value: 0, growth: 0 },
          customerCount: { value: 0, growth: 0 },
          averageTicket: { value: 0, growth: 0 },
          pendingRequests: { value: 0 },
          reminders: { value: 0 }
        }
      });

    } catch (err) {
      console.error('Dashboard fetch error:', err);
      setError(err.message || 'Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchDashboardData();
    }
  }, [startDate, endDate]);

  const barChartData = {
    labels: dashboardData.revenueData.map(d => d.month),
    datasets: [{
      label: 'Revenue',
      data: dashboardData.revenueData.map(d => d.total),
      backgroundColor: '#4DD0E1'
    }]
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: context => formatCurrency(context.raw)
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: value => formatCurrency(value)
        }
      }
    }
  };

  const maxRevenue = Math.max(...dashboardData.revenueData.map(d => d.total), 100000);
  const targetRevenue = maxRevenue * 1.2;

  const gaugeMetrics = [
    {
      title: "Revenue",
      value: dashboardData.revenueData.reduce((sum, d) => sum + d.total, 0),
      maxValue: targetRevenue,
      formatValue: (v) => formatCurrency(v)
    },
    {
      title: "Average Ticket",
      value: dashboardData.kpiData.averageTicket?.value || 0,
      maxValue: 2000,
      formatValue: (v) => formatCurrency(v)
    },
    {
      title: "Customer Count",
      value: dashboardData.kpiData.customerCount?.value || 0,
      maxValue: 1000,
      formatValue: (v) => Math.round(v).toString()
    }
  ];

  const financialKpis = [
    {
      title: "Total Expenditure",
      value: dashboardData.kpiData.totalExpenditure?.value || 0,
      growth: dashboardData.kpiData.totalExpenditure?.growth || 0
    },
    {
      title: "Output VAT",
      value: dashboardData.kpiData.outputVat?.value || 0,
      growth: dashboardData.kpiData.outputVat?.growth || 0
    },
    {
      title: "Input VAT",
      value: dashboardData.kpiData.inputVat?.value || 0,
      growth: dashboardData.kpiData.inputVat?.growth || 0
    },
    {
      title: "VAT Difference",
      value: dashboardData.kpiData.vatDifference?.value || 0,
      growth: dashboardData.kpiData.vatDifference?.growth || 0
    }
  ];

  const operationalKpis = [
    {
      title: "Customer Count",
      value: dashboardData.kpiData.customerCount?.value || 0,
      growth: dashboardData.kpiData.customerCount?.growth || 0
    },
    {
      title: "Average Ticket",
      value: dashboardData.kpiData.averageTicket?.value || 0,
      growth: dashboardData.kpiData.averageTicket?.growth || 0
    },
    {
      title: "Pending Requests",
      value: dashboardData.kpiData.pendingRequests?.value || 0
    },
    {
      title: "Reminders",
      value: dashboardData.kpiData.reminders?.value || 0
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 2
      }}>
        <Typography variant="h4">Manager Dashboard</Typography>
      </Box>

      <DatePickerComponent
        startDate={startDate}
        endDate={endDate}
        setStartDate={handleStartDateChange}
        setEndDate={handleEndDateChange}
      />

      {!datesSelected && !savedStartDate && (
        <Alert severity="info" sx={{ my: 2 }}>
          <AlertTitle>Select Dates</AlertTitle>
          Please select a date range to view the dashboard data.
        </Alert>
      )}

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}

      {!loading && !error && datesSelected && (
        <>
          {dashboardData.revenueData.length === 0 ? (
            <Alert severity="info" sx={{ my: 2 }}>
              <AlertTitle>No Data Available</AlertTitle>
              No income data found for the selected date range.
            </Alert>
          ) : (
            <>
              {/* Gauge Charts */}
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
                gap: 2,
                mb: 4
              }}>
                {gaugeMetrics.map((metric, index) => (
                  <Box key={index} sx={{ backgroundColor: 'white', p: 2, borderRadius: 1 }}>
                    <Typography align="center" gutterBottom>{metric.title}</Typography>
                    <GaugeChart
                      id={`gauge-${index}`}
                      nrOfLevels={10}
                      percent={Math.min(metric.value / metric.maxValue, 1)}
                      colors={["#FF5F6D", "#FFC371"]}
                      arcWidth={0.3}
                      needleColor="#345243"
                      formatTextValue={() => metric.formatValue(metric.value)}
                    />
                  </Box>
                ))}
              </Box>

              {/* Revenue Chart */}
              <Box sx={{ 
                backgroundColor: 'white',
                p: 3,
                mb: 4,
                borderRadius: 1,
                height: '400px'
              }}>
                <Typography variant="h6" gutterBottom>Monthly Revenue</Typography>
                <Bar data={barChartData} options={barOptions} />
              </Box>

              {/* Operational KPIs */}
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr 1fr' },
                gap: 2,
                mb: 2
              }}>
                {operationalKpis.map((kpi, index) => (
                  <KPIBox
                    key={index}
                    title={kpi.title}
                    value={kpi.value}
                    growth={kpi.growth}
                  />
                ))}
              </Box>

              {/* Financial KPIs */}
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr 1fr' },
                gap: 2,
                mb: 4
              }}>
                {financialKpis.map((kpi, index) => (
                  <KPIBox
                    key={index}
                    title={kpi.title}
                    value={kpi.value}
                    growth={kpi.growth}
                  />
                ))}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ManagerDashboard;