import React, { useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import api from './axiosConfig';
import { Box, Paper, Typography, CircularProgress, Alert, Grid, Button } from '@mui/material';
import DatePickerComponent from './DatePickerComponent';
import { Bar } from 'react-chartjs-2';
import { useAuth } from './AuthContext';
import dayjs from 'dayjs';
import GaugeChart from 'react-gauge-chart';
import KPIBox from './KPIBox';  // Make sure this import path is correct

import { Chart, LinearScale, CategoryScale, BarController, BarElement } from 'chart.js';
Chart.register(LinearScale, CategoryScale, BarController, BarElement);

const OwnerDashboard = () => {
  const { isAuthenticated, user } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'year').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  const fetchDashboardData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [kpiResponse, revenueResponse, requestsResponse] = await Promise.all([
        api.get('/api/dashboard/kpi_data', { 
          params: {
            start_date: startDate.format('YYYY-MM-DD'), 
            end_date: endDate.format('YYYY-MM-DD') 
          }
        }),
        api.get('/api/dashboard/revenue_data', { 
          params: {
            start_date: startDate.format('YYYY-MM-DD'), 
            end_date: endDate.format('YYYY-MM-DD')
          }
        }),
        api.get('/api/dashboard/pending_requests')
      ]);

      console.log('Raw KPI Response:', kpiResponse.data);
      console.log('Raw Revenue Response:', revenueResponse.data);
      console.log('Raw Pending Requests Response:', requestsResponse.data);

      if (kpiResponse.data.error || revenueResponse.data.error || requestsResponse.data.error) {
        throw new Error('Error in one of the API responses');
      }

      setDashboardData({
        kpiData: kpiResponse.data,
        revenueData: revenueResponse.data,
        pendingRequests: requestsResponse.data.pending_requests
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to load dashboard data. Please check your network connection and try again.');
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (isAuthenticated && user?.role === 'ACCOUNT_OWNER') {
      fetchDashboardData();
    }
  }, [isAuthenticated, user, fetchDashboardData]);

  if (!isAuthenticated || user?.role !== 'ACCOUNT_OWNER') {
    return <Navigate to="/unauthorized" replace />;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>Loading dashboard data...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
        <Button onClick={fetchDashboardData} sx={{ mt: 2 }}>Retry</Button>
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">No dashboard data available.</Alert>
        <Button onClick={fetchDashboardData} sx={{ mt: 2 }}>Refresh Data</Button>
      </Box>
    );
  }

  console.log('Dashboard Data:', dashboardData);

  const { kpiData, revenueData, pendingRequests } = dashboardData;

  const barChartData = {
    labels: revenueData.monthlyData?.map(item => item.month) || [],
    datasets: [{
      label: 'Monthly Revenue',
      data: revenueData.monthlyData?.map(item => item.total) || [],
      backgroundColor: 'rgba(75,192,192,1)',
    }]
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Revenue (£)'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
   <Box className="dashboard-container" sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Account Owner Dashboard
      </Typography>
      <DatePickerComponent startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
      <Button onClick={fetchDashboardData} sx={{ mt: 2, mb: 2 }}>Refresh Data</Button>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 4 }}>
        <GaugeChart id="revenue-gauge" 
          nrOfLevels={10} 
          percent={(revenueData.annualTotal || 0) / 100000} 
          textColor="#000000"
          formatTextValue={() => `£${(revenueData.annualTotal || 0).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}
        />
        <GaugeChart id="expenditure-gauge" 
          nrOfLevels={10} 
          percent={(kpiData.expenditure?.value || 0) / 50000} 
          textColor="#000000"
          formatTextValue={() => `£${(kpiData.expenditure?.value || 0).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}
        />
        <GaugeChart id="profit-gauge" 
          nrOfLevels={10} 
          percent={((revenueData.annualTotal || 0) - (kpiData.expenditure?.value || 0)) / 50000} 
          textColor="#000000"
          formatTextValue={() => `£${((revenueData.annualTotal || 0) - (kpiData.expenditure?.value || 0)).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}
        />
      </Box>

      <Paper elevation={3} sx={{ p: 2, mb: 4, height: 400 }}>
        <Typography variant="h6" gutterBottom>Monthly Revenue</Typography>
        <Bar data={barChartData} options={barOptions} />
      </Paper>

      <Grid container spacing={3}>
        {Object.entries(kpiData).map(([key, data]) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
            <KPIBox 
              title={key}
              value={data.value}
              growth={data.growth}
              prefix={['expenditure', 'vatCharged', 'vatPaid', 'vatDifference', 'avgTicket'].includes(key) ? '£' : ''}
              isWholeNumber={['tickets', 'reminders'].includes(key)}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <KPIBox 
            title="Pending Holiday Requests" 
            value={pendingRequests} 
            growth={0}
            isWholeNumber={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OwnerDashboard;