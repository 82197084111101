import React, { useEffect } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Box, Button } from '@mui/material';
import { useAuth } from './AuthContext';
import logo from './Salon Logic Square 2.gif';

// Import Material-UI icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BusinessIcon from '@mui/icons-material/Business';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import NoteIcon from '@mui/icons-material/Note';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';

const Layout = () => {
  const { user, logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('Layout mounted:', { 
      isAuthenticated, 
      user, 
      currentPath: location.pathname 
    });

    if (!isAuthenticated || !user) {
      console.log('No auth detected, redirecting to login');
      navigate('/login');
      return;
    }

    // Redirect to appropriate dashboard based on role
    if (location.pathname === '/') {
      switch (user.role) {
        case 'ACCOUNT_MANAGER':
          navigate('/manager_dashboard');
          break;
        case 'ACCOUNT_OWNER':
          navigate('/owner_dashboard');
          break;
        case 'ACCOUNT_USER':
          navigate('/user_dashboard');
          break;
        default:
          navigate('/dashboard');
      }
    }
  }, [isAuthenticated, user, navigate, location.pathname]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // If not authenticated, don't render anything
  if (!isAuthenticated || !user) {
    return null;
  }

  const menuItems = [
    { title: 'Dashboards', path: '/dashboard', icon: <DashboardIcon /> },
    {
      title: 'Employees',
      icon: <PeopleIcon />,
      items: [
        { title: 'Employee Card', path: '/employee_card' },
        { title: 'Submit Employee Review', path: '/submit_employee_review' },
        { title: 'Employee Reviews', path: '/employee_reviews' },
      ],
    },
    {
      title: 'Time Management',
      icon: <AccessTimeIcon />,
      items: [
        { title: 'Holiday Calendar', path: '/holiday_calendar' },
        { title: 'Request Holiday', path: '/submit_holiday_request' },
        { title: 'Submit Absence', path: '/submit_absence' },
        { title: 'Holiday Approval', path: '/holiday_approval' },
      ],
    },
    { title: 'Suppliers', path: '/supplier_card', icon: <BusinessIcon /> },
    { title: 'Income', path: '/income_card', icon: <CurrencyPoundIcon /> },
    { title: 'Notes', path: '/notes', icon: <NoteIcon /> },
    { title: 'Reminders', path: '/reminders', icon: <NotificationsIcon /> },
    { title: 'Space Rental', path: '/space_rental_card', icon: <HomeWorkIcon /> },
    { title: 'Employment Applications', path: '/employment_applications', icon: <WorkIcon /> },
    { title: 'Submit Training', path: '/submit_training', icon: <SchoolIcon /> },
  ];

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static" sx={{ borderRadius: '12px', margin: '16px 16px 0 16px', backgroundColor: '#1976d2' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Salon Management System
          </Typography>
          {user && (
            <Typography variant="subtitle1">
              Welcome, {user.email}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', margin: '16px' }}>
        <Box sx={{ 
          flexGrow: 1,
          backgroundColor: '#e3f2fd',
          borderRadius: '12px',
          display: 'flex',
        }}>
          <Box sx={{ 
            width: 250,
            backgroundColor: '#1976d2',
            borderRadius: '12px 0 0 12px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
          }}>
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
              <img src={logo} alt="Salon Logic Logo" style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              {menuItems.map((item, index) => (
                item.items ? (
                  <Box key={index} sx={{ marginBottom: '10px' }}>
                    <Typography sx={{ color: 'white', padding: '8px 0', display: 'flex', alignItems: 'center' }}>
                      {item.icon}
                      <span style={{ marginLeft: '8px' }}>{item.title}</span>
                    </Typography>
                    {item.items.map((subItem, subIndex) => (
                      <Link key={subIndex} to={subItem.path} style={{ textDecoration: 'none' }}>
                        <Typography sx={{ color: 'white', padding: '4px 0 4px 32px', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>
                          {subItem.title}
                        </Typography>
                      </Link>
                    ))}
                  </Box>
                ) : (
                  <Link key={index} to={item.path} style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: 'white', padding: '8px 0', display: 'flex', alignItems: 'center', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>
                      {item.icon}
                      <span style={{ marginLeft: '8px' }}>{item.title}</span>
                    </Typography>
                  </Link>
                )
              ))}
            </Box>
            <Button onClick={handleLogout} variant="contained" color="secondary" sx={{ marginTop: '20px' }}>
              Logout
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '16px' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Box component="footer" sx={{ py: 3, px: 2, backgroundColor: (theme) => theme.palette.grey[200], borderRadius: '12px', margin: '0 16px 16px 16px' }}>
        <Container maxWidth="sm">
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} Salon Management System
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;