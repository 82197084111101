import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { 
  Grid, Paper, Typography, Container, CircularProgress, Alert, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  TextField, Button, Box, Switch, FormControlLabel
} from '@mui/material';
import { useAuth } from './AuthContext';

const AdminDashboard = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [users, setUsers] = useState([]);
  const [vatRates, setVatRates] = useState({
    standard_rate: '',
    first_year_rate: '',
    following_year_rate: ''
  });
  const [newUser, setNewUser] = useState({
    email: '',
    tempPassword: '',
    role: 'ACCOUNT_OWNER',
    has_free_access: true
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dashboardResponse, usersResponse] = await Promise.all([
          api.get('/api/admin/dashboard'),
          api.get('/api/admin/users')
        ]);
        setDashboardData(dashboardResponse.data);
        setUsers(usersResponse.data);
        setVatRates(dashboardResponse.data.vat_rates);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching admin data:', error);
        setError('Failed to load admin data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleVatRateChange = (event) => {
    setVatRates({
      ...vatRates,
      [event.target.name]: event.target.value
    });
  };

  const handleVatRateSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post('/api/admin/vat-rates', vatRates);
      alert('VAT rates updated successfully');
    } catch (error) {
      console.error('Error updating VAT rates:', error);
      alert('Failed to update VAT rates');
    }
  };

  const handleNewUserChange = (event) => {
    const { name, value, checked } = event.target;
    setNewUser({
      ...newUser,
      [name]: name === 'has_free_access' ? checked : value
    });
  };

  const handleCreateUser = async (event) => {
    event.preventDefault();
    try {
      await api.post('/api/admin/create-user', newUser);
      alert('User created successfully');
      // Refresh user list
      const response = await api.get('/api/admin/users');
      setUsers(response.data);
      // Clear form
      setNewUser({ email: '', tempPassword: '', role: 'ACCOUNT_OWNER', has_free_access: true });
    } catch (error) {
      console.error('Error creating user:', error);
      alert('Failed to create user');
    }
  };

  const handleToggleFreeAccess = async (userId, currentStatus) => {
    try {
      await api.put(`/api/admin/users/${userId}/toggle-free-access`, { has_free_access: !currentStatus });
      // Refresh user list
      const response = await api.get('/api/admin/users');
      setUsers(response.data);
      alert('User free access status updated successfully');
    } catch (error) {
      console.error('Error updating user free access status:', error);
      alert('Failed to update user free access status');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Total Users</Typography>
            <Typography variant="h4">{dashboardData?.total_users}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>VAT Rates</Typography>
            <form onSubmit={handleVatRateSubmit}>
              <TextField
                label="Standard Rate"
                name="standard_rate"
                value={vatRates.standard_rate}
                onChange={handleVatRateChange}
                type="number"
                fullWidth
                margin="normal"
              />
              <TextField
                label="First Year Rate"
                name="first_year_rate"
                value={vatRates.first_year_rate}
                onChange={handleVatRateChange}
                type="number"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Following Year Rate"
                name="following_year_rate"
                value={vatRates.following_year_rate}
                onChange={handleVatRateChange}
                type="number"
                fullWidth
                margin="normal"
              />
              <Button type="submit" variant="contained" color="primary">
                Update VAT Rates
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Create New User</Typography>
            <form onSubmit={handleCreateUser}>
              <TextField
                label="Email"
                name="email"
                value={newUser.email}
                onChange={handleNewUserChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Temporary Password"
                name="tempPassword"
                value={newUser.tempPassword}
                onChange={handleNewUserChange}
                type="password"
                fullWidth
                margin="normal"
                required
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={newUser.has_free_access}
                    onChange={handleNewUserChange}
                    name="has_free_access"
                    color="primary"
                  />
                }
                label="Free Access"
              />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                Create User
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>User List</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Free Access</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.has_free_access ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        <Button 
                          onClick={() => handleToggleFreeAccess(user.id, user.has_free_access)}
                          variant="outlined"
                          color={user.has_free_access ? "secondary" : "primary"}
                        >
                          {user.has_free_access ? 'Remove Free Access' : 'Grant Free Access'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;