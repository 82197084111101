import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { List, ListItem, ListItemText, Paper, Typography, TextField, Button, Box } from '@mui/material';

const Notes = ({ employee, isEditable }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (employee && employee.id) {
      fetchNotes(employee.id);
    }
  }, [employee]);

  const fetchNotes = async (employeeId) => {
    try {
      setLoading(true);
      const response = await api.get(`/notes/employee/${employeeId}`);
      setNotes(response.data);
    } catch (err) {
      console.error('Error fetching notes:', err);
      setError('Failed to fetch notes');
    } finally {
      setLoading(false);
    }
  };

  const handleAddNote = async () => {
    if (!newNote.trim()) return;

    try {
      const response = await api.post('/notes', {
        employee_id: employee.id,
        content: newNote
      });
      setNotes([...notes, response.data]);
      setNewNote('');
    } catch (err) {
      console.error('Error adding note:', err);
      setError('Failed to add note');
    }
  };

  if (loading) return <Typography>Loading notes...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Paper sx={{ p: 2 }}>
      <List>
        {notes.map((note) => (
          <ListItem key={note.id}>
            <ListItemText 
              primary={note.content}
              secondary={new Date(note.created_at).toLocaleString()}
            />
          </ListItem>
        ))}
      </List>
      {isEditable && (
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            label="New Note"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAddNote}
            sx={{ mt: 1 }}
          >
            Add Note
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default Notes;