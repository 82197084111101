import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  CircularProgress, 
  Alert, 
  Button, 
  Snackbar, 
  Tabs, 
  Tab,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import KPIBox from './KPIBox';

const UserDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    holidayBalance: 0,
    totalDays: 0,
    takenDays: 0,
    pendingDays: 0,
    holidayRequests: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    numberOfDaysHoliday: '',
    notes: ''
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/api/dashboard/user/dashboard');
      setDashboardData(response.data);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError(`Failed to load dashboard data. Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'startDate' || name === 'endDate') {
      if (formData.startDate && formData.endDate) {
        const start = new Date(formData.startDate);
        const end = new Date(formData.endDate);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        setFormData(prev => ({ ...prev, numberOfDaysHoliday: diffDays }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/api/dashboard/user/submit-holiday', formData);
      showSnackbar('Holiday request submitted successfully', 'success');
      resetForm();
      fetchDashboardData();
    } catch (error) {
      console.error('Error submitting holiday request:', error);
      showSnackbar('Error submitting holiday request', 'error');
    }
  };

  const resetForm = () => {
    setFormData({
      startDate: '',
      endDate: '',
      numberOfDaysHoliday: '',
      notes: ''
    });
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const renderHolidayRequests = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Days</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboardData.holidayRequests.map((request, index) => (
            <TableRow key={index}>
              <TableCell>{request.start_date}</TableCell>
              <TableCell>{request.end_date}</TableCell>
              <TableCell>{request.number_of_days_holiday}</TableCell>
              <TableCell>{request.status}</TableCell>
              <TableCell>{request.notes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderHolidayForm = () => (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Submit Holiday Request
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Number of Days"
              type="number"
              name="numberOfDaysHoliday"
              value={formData.numberOfDaysHoliday}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              multiline
              rows={4}
              name="notes"
              value={formData.notes}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit Request
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box maxWidth="lg" sx={{ mt: 4, mb: 4, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          User Dashboard
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Holiday Balance" value={`${dashboardData.holidayBalance} days`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Total Days" value={`${dashboardData.totalDays} days`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Taken Days" value={`${dashboardData.takenDays} days`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Pending Days" value={`${dashboardData.pendingDays} days`} />
          </Grid>
        </Grid>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} sx={{ mb: 2, mt: 2 }}>
          <Tab label="Holiday Requests" />
          <Tab label="Submit Request" />
        </Tabs>
        {activeTab === 0 ? renderHolidayRequests() : renderHolidayForm()}
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserDashboard;