import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Layout from './Layout';
import ProtectedRoute from './ProtectedRoute';
import ErrorBoundary from './ErrorBoundary';

// Public routes
import LandingPage from './LandingPage';
import Login from './Login';
import Register from './Register';
import PasswordRecovery from './PasswordRecovery';
import PasswordReset from './PasswordReset';
import RegistrationSuccess from './RegistrationSuccess';

// Protected routes
import Dashboard from './Dashboard';
import AdminDashboard from './AdminDashboard';
import OwnerDashboard from './OwnerDashboard';
import ManagerDashboard from './ManagerDashboard';
import UserDashboard from './UserDashboard';
import EmployeeCard from './EmployeeCard';
import EmployeeReviews from './EmployeeReviews';
import HolidayCalendar from './HolidayCalendar';
import SubmitHolidayRequest from './SubmitHolidayRequest';
import SubmitAbsence from './SubmitAbsence';
import HolidayApproval from './HolidayApproval';
import SupplierCard from './SupplierCard';
import IncomeCard from './IncomeCard';
import Notes from './Notes';
import PersonalInfo from './PersonalInfo';
import SubmitTraining from './SubmitTraining';
import Disciplinary from './Disciplinary';
import SpaceRentalCard from './SpaceRentalCard';
import EmploymentApplications from './EmploymentApplications';
import DocumentApproval from './DocumentApproval';
import Reminders from './Reminders';
import SubmitEmployeeReview from './SubmitEmployeeReview';
import Unauthorized from './Unauthorized';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <AuthProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/password-recovery" element={<PasswordRecovery />} />
            <Route path="/password-reset/:token" element={<PasswordReset />} />
            <Route path="/registration-success" element={<RegistrationSuccess />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            
            {/* Protected routes wrapped in Layout */}
            <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/admin_dashboard" element={
                <ProtectedRoute allowedRoles={['ADMIN']}>
                  <AdminDashboard />
                </ProtectedRoute>
              } />
              <Route path="/owner_dashboard" element={
                <ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}>
                  <OwnerDashboard />
                </ProtectedRoute>
              } />
              <Route path="/manager_dashboard" element={
                <ProtectedRoute allowedRoles={['ACCOUNT_MANAGER']}>
                  <ManagerDashboard />
                </ProtectedRoute>
              } />
              <Route path="/user_dashboard" element={
                <ProtectedRoute allowedRoles={['ACCOUNT_USER']}>
                  <UserDashboard />
                </ProtectedRoute>
              } />
              <Route path="/employee_card" element={<EmployeeCard />} />
              <Route path="/employee_reviews" element={<EmployeeReviews />} />
              <Route path="/holiday_calendar" element={<HolidayCalendar />} />
              <Route path="/submit_holiday_request" element={<SubmitHolidayRequest />} />
              <Route path="/submit_absence" element={<SubmitAbsence />} />
              <Route path="/holiday_approval" element={<HolidayApproval />} />
              <Route path="/supplier_card" element={<SupplierCard />} />
              <Route path="/income_card" element={<IncomeCard />} />
              <Route path="/notes" element={<Notes />} />
              <Route path="/reminders" element={<Reminders />} />
              <Route path="/personal_info" element={<PersonalInfo />} />
              <Route path="/submit_training" element={<SubmitTraining />} />
              <Route path="/disciplinary" element={<Disciplinary />} />
              <Route path="/space_rental_card" element={<SpaceRentalCard />} />
              <Route path="/submit_employee_review" element={<SubmitEmployeeReview />} />
              <Route path="/employment_applications" element={<EmploymentApplications />} />
              <Route path="/document_approval" element={<DocumentApproval />} />
            </Route>

            {/* Catch-all redirect */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </AuthProvider>
      </ErrorBoundary>
    </Router>
  );
}

export default App;