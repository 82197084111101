import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { 
    TextField, 
    Button, 
    Typography, 
    Container, 
    Box, 
    CircularProgress 
} from '@mui/material';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { login, isLoggingIn } = useAuth();

    const handleLogin = async (e) => {
    e.preventDefault();
    if (isLoggingIn) return;

    setError('');
    try {
        const result = await login(email, password);
        console.log('Login result:', result);

        if (result.success) {
            // Verify storage immediately after login
            console.log('Storage after login:', {
                token: !!localStorage.getItem('token'),
                refresh_token: !!localStorage.getItem('refresh_token'),
                user: localStorage.getItem('user')
            });
        } else {
            setError(result.error || 'Login failed. Please check your credentials.');
        }
    } catch (error) {
        console.error('Login error:', error);
        setError('An error occurred during login. Please try again.');
    }
};

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5">Login</Typography>
                <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={isLoggingIn}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={isLoggingIn}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={isLoggingIn}
                    >
                        {isLoggingIn ? <CircularProgress size={24} /> : 'Sign In'}
                    </Button>
                </Box>
                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default Login;