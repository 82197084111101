import React from 'react';
import { Paper, Typography } from '@mui/material';

const KPIBox = ({ title, value, growth, prefix = '', isWholeNumber = false }) => {
  const formattedTitle = title.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  let formattedValue = 'N/A';
  
  if (value !== null && value !== undefined) {
    if (isWholeNumber) {
      formattedValue = Math.round(value).toLocaleString('en-GB');
    } else if (typeof value === 'number') {
      formattedValue = value.toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }
  }

  return (
    <Paper elevation={3} sx={{ p: 2, textAlign: 'center', height: '100%' }}>
      <Typography variant="subtitle1">{formattedTitle}</Typography>
      <Typography variant="h4">
        {value !== null && value !== undefined ? `${prefix}${formattedValue}` : formattedValue}
      </Typography>
      {growth !== undefined && growth !== null && (
        <Typography color={growth >= 0 ? "success.main" : "error.main"}>
          {growth.toFixed(2)}% {growth >= 0 ? "▲" : "▼"}
        </Typography>
      )}
    </Paper>
  );
};

export default KPIBox;